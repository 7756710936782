import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Authorization from 'utils/Authorization';
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import './ValidateEmployee.css'; // Import CSS

const ValidateEmployee = () => {
  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState('Verifying...');
  const [email, setEmail] = useState(null);
  const [employeeCode, setEmployeeCode] = useState(null);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const token = Authorization.getAccessToken();
    setAccessToken(token);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const statusFromQuery = searchParams.get('status')?.replace(/\\/g, '').trim();

    if (!token) {
      setStatus('Invalid verification link');
      return;
    }

    try {
      const decoded = jwtDecode(token);
      const { email, employee_code } = decoded;
      setEmail(email);
      setEmployeeCode(employee_code);

      axios({
        method: 'put',
        url: `${API_BASE_URL}employee/validateEmployee`,
        data: {
          employee_code,
          status: statusFromQuery
        }
      })
        .then(() => {
          setStatus('Employee validated successfully!');
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setStatus(
              'Verification failed!' +
                (error.response?.data?.message || error.message)
            );
          } else {
            setStatus(
              'Verification failed!' +
                (error.response?.data?.message || error.message)
            );
          }
        });
    } catch (error) {
      setStatus('Invalid or expired token.');
    }
  }, [location]);

  return (
    <div className="validation-container">
      <div className="validation-box">
        <h1 className="title">Employee Validation</h1>
        <h3 className="subtitle">Secure verification process</h3>
        <p
          className={`status-message ${
            status.includes('successfully') || status.includes('Test Mode')
              ? 'success'
              : 'error'
          }`}
        >
          {status}
        </p>
        {/* {email && <p className="email">✔ Email Verified: <strong>{email}</strong></p>} */}

        {email ? (
          <p className="email">
            {status.includes('successfully')
              ? '✔ Email Verified: '
              : '❌ Email not verified: '}
            <strong>{email}</strong>
            <p className="email">
              Employee Code: <strong>{employeeCode}</strong>
            </p>
          </p>
        ) : (
          <p className="email error">❌ Email not verified</p>
        )}

        {status.includes('successfully') && !accessToken && (
          <button
            className="login-btn"
            onClick={() => history.push('/auth/login')}
          >
            Go to Login
          </button>
        )}
      </div>
    </div>
  );
};

export default ValidateEmployee;
